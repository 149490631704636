import { template as template_2e574a21753d4224ba516bf3fa3954ea } from "@ember/template-compiler";
const FKText = template_2e574a21753d4224ba516bf3fa3954ea(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
