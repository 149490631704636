import { template as template_b896c4040c194724ad3d5d9627c31518 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b896c4040c194724ad3d5d9627c31518(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
